import React, { useMemo } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useThemeContext } from "../../structure/theme"
import { responsiveHamburger } from "../menu/responsiveHamburger"
import Section from "../../../components/section.component"
import { createSelfCareLink } from "../navigation.utils"
import PhoneCta from "../../contact/phoneCta.component"
import { firstLetterUppercase } from "../../../utils/format"
import { useTranslation } from "../../translation/translate.component"
import usePhoneCostNumber from "../../contact/phoneCostNumber.hook"
import { useUserContext } from "../user.provider"

const Container = styled.div`
  display: none;
  justify-content: space-between;
  height: 38px;
  align-items: center;
  color: white;

  ${responsiveHamburger`
    display: flex;
  `}
`

const Item = styled.li`
  list-style: none;
  display: inline-block;

  ::after {
    content: "|";
    display: inline-block;
    margin: 0 10px;
  }

  &:last-of-type::after {
    content: none;
  }
`

const Number = styled.span`
  margin-right: 10px;
`

const ServiceNumber = styled.span`
  display: flex;
  align-items: center;
`

const Link = styled.a.attrs(() => ({ target: `_blank` }))`
  text-decoration: none;
  color: inherit;
`

export default function PreHeader ({ coBrand, phoneNumber }) {
  const { t } = useTranslation()
  const { isSimplifiedNavigation } = useUserContext()
  const theme = useThemeContext()
  const phoneCostNumber = usePhoneCostNumber()

  const selfCareLink = useMemo(
    () =>
      createSelfCareLink(
        `/questions-frequentes?lien_interne=Header_Assistance&_ga=2.64851631.1264877968.1583920281-577755280.1580887828`,
      ),
    [],
  )

  if (coBrand || isSimplifiedNavigation) {
    return null
  }

  return (
    <Section bgColor={theme.colors.grey.g800} bgWidth="full">
      <Container data-testid="preheader">
        <ServiceNumber>
          <Number>
            <PhoneCta>{phoneNumber}</PhoneCta>
          </Number>
          {phoneCostNumber && <span>{firstLetterUppercase(phoneCostNumber)}</span>}
        </ServiceNumber>
        <ul>
          <Item>
            <Link href="https://www.homeserve.fr/?lien_interne=Header_Assistance">
              {t(`common:navigation.pre_header_site`)}
            </Link>
          </Item>
          {APP_CONFIG.featureFlags.selfcare && (
            <Item>
              <Link href={selfCareLink}>{t(`common:navigation.pre_header_help`)}</Link>
            </Item>
          )}
        </ul>
      </Container>
    </Section>
  )
}

PreHeader.propTypes = {
  coBrand: PropTypes.object,
  phoneNumber: PropTypes.string,
}
