import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Cta = styled.a`
  text-decoration: inherit;
  color: inherit;
`

export default function PhoneCta ({ children }) {
  return <Cta href={`tel:${children}`}>{children}</Cta>
}

PhoneCta.propTypes = {
  children: PropTypes.string.isRequired,
}
